import DecisionPage from "./AadaVoucherComponents/DecisionPage";
// import DecisionPage from "./components/DecisionPage";

function AadaVoucherDecisionApp() {
  return (
    <div >
      <DecisionPage />
    </div>
  );
}

export default AadaVoucherDecisionApp;
